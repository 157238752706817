import React from "react";
import mediumZoom from 'medium-zoom'
import "../../fonts/fonts.css";
import "../../pages/styles.scss";
import "./styles.scss";
import { GatsbyImage } from "gatsby-plugin-image"

const GalleryComponent = ({ title, renders, totalCount }) => {
  // const loadedRefTimeoutId = useRef(null);
  const onLoad = () => {
    // clearTimeout(loadedRefTimeoutId.current);
    // loadedRefTimeoutId.current = setTimeout(() => {
      mediumZoom('img[data-zoomable]', { background: '#212530' })
    // }, 500);
  }
  return (
    <div className="container">
      {renders.map((singleRow, index) => {
        if (singleRow.length === 1) {
          return (<div className="split split-1" key={`portfolio-image-${index}`}>
            <GatsbyImage onLoad={onLoad} data-zoomable image={singleRow[0]} alt={`${title}-${index}`} />
          </div>)
        } else if (singleRow.length === 2) {
          return (<div className="split split-2" key={`portfolio-image-${index}`}>
            <GatsbyImage onLoad={onLoad} data-zoomable image={singleRow[0]} alt={`${title}-${index}`} />
            <GatsbyImage onLoad={onLoad} data-zoomable image={singleRow[1]} alt={`${title}-${index}`} />
          </div>)
        }
      })}
    </div>
  );
};


export default GalleryComponent;
