import * as React from "react";
import { graphql } from "gatsby";

import "../fonts/fonts.css";
import "../pages/styles.scss";
import "./styles.scss";
import Header from "../components/header";
import Footer from "../components/footer";
import GalleryComponent from "../components/gallery-component";
import DATA from "../data";

const prepareRenders = (rendersLayout, gatsbyImages) => {
  const rendersDictionary = {};
  gatsbyImages.forEach((render) => {
    rendersDictionary[render.node.name] =
      render.node.childImageSharp.gatsbyImageData;
  });
  // console.log('rendersDictionary', rendersDictionary);

  const renders = rendersLayout.map((singleRow) =>
    singleRow.map((singleRender) => rendersDictionary[singleRender])
  );
  // console.log('renders', renders);
  return renders;
};

// markup
const PortfolioDetailsPage = ({ data }) => {
  const { markdownRemark, allFile } = data; // data.markdownRemark holds your post data
  const { frontmatter: meta } = markdownRemark;

  const renders = prepareRenders(meta.rendersLayout, allFile.edges);

  return (
    <main id="portfolio-details">
      <title>
        {DATA.title} - Portfolio | {meta.title}
      </title>

      <Header withNavigation />
      <div className="content">
        <div className="inner">
          <div className="description">
            <h1>{meta.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: meta.description }}></p>
            {meta.client && (
              <React.Fragment>
                <h4>Client</h4>
                <p>{meta.client}</p>
              </React.Fragment>
            )}
            {meta.location && (
              <React.Fragment>
                <h4>Location</h4>
                <p>{meta.location}</p>
              </React.Fragment>
            )}
          </div>
          <GalleryComponent
            renders={renders}
            title={meta.title}
            totalCount={allFile.totalCount}
          />
          {meta.video && (
            <iframe
              title={meta.title}
              src={meta.video}
              width="1500"
              height="900"
              style={{ maxWidth: "100%" }}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          {meta.roundme &&
            meta.roundme.length &&
            meta.roundme.map((roundmeLink, index) => (
              <iframe
                key={`roundme-key-${index}`}
                title={meta.title}
                src={roundmeLink}
                width="1500"
                height="900"
                style={{ maxWidth: "100%" }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              ></iframe>
            ))}
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default PortfolioDetailsPage;

export const pageQuery = graphql`
  query ($slug: String!, $relativeSlug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        client
        location
        video
        rendersLayout
        roundme
      }
    }
    allFile(filter: { relativeDirectory: { eq: $relativeSlug } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          name
        }
      }
      totalCount
    }
  }
`;
